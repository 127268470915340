@font-face {
    font-family: "Swiss Re Sans";
    src: local('SRfont'), url("../src/fonts/180618_swissresansot-regular-webfont.woff2") format("woff2");
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Swiss Re Sans";
    src: local('SRfontlight') url("../src/fonts/180618_swissresansot-light-webfont.woff2") format("woff2");
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: "Swiss Re Sans";
    src: local('SRfontnold') url("../src/fonts/180618_swissresansot-bold-webfont.woff2") format("woff2");
    font-weight: bold;
    font-display: swap;
}